import React from "react";
import Icon from "@/components/icons";

export interface CertificateTestimonialProps {
  title?: string;
  testimonial: string;
  attribution: string;
}

export default function CertificateTestimonial({
  title,
  testimonial,
  attribution,
}: CertificateTestimonialProps) {
  return (
    <div className="flex flex-col gap-6">
      {title && (
        <h2 className="my-0 text-putty-300 italic font-bold text-3xl md:text-4xl tracking-[-0.06rem]">
          {title}
        </h2>
      )}
      <div className="flex flex-col gap-7 font-semibold">
        <div className="flex flex-col gap-2">
          <Icon
            type="open-quote"
            svgProps={{ width: 20, height: 15 }}
            pathProps={{ fill: "white" }}
          />
          <p className="text-lg text-white mb-0">{testimonial}</p>
        </div>
        <p className="text-sm text-white mb-0">- {attribution}</p>
      </div>
    </div>
  );
}
