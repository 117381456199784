import React from "react";
export interface CertificateStatsProps {
  title?: string;
  stats: {
    percentageNumber: number;
    message: string;
    source: string;
  }[];
}

export default function CertificateStats({
  title,
  stats,
}: CertificateStatsProps) {
  return (
    <div className="flex flex-col gap-6">
      {title && (
        <h2 className="my-0 text-putty-300 italic font-bold text-3xl md:text-4xl tracking-[-0.06rem]">
          {title}
        </h2>
      )}
      <div className="flex flex-col gap-6">
        {stats.map((stat) => (
          <div key={stat.message} className="flex flex-col gap-2">
            <div className="flex gap-6 items-center">
              <p className="text-5xl lg:text-7xl text-putty-300 m-0 font-bold italic">
                {stat.percentageNumber}%
              </p>
              <p className="text-white m-0 font-normal">{stat.message}</p>
            </div>
            <p className="text-white italic text-xs mb-0">
              Source: {stat.source}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
