import React, { useEffect } from "react";
import { ProcessedCourse } from "@/lib/course/types";
import { CourseRun } from "@/lib/courseRun/types";
import CertificateInfo from "../CertificateInfo";
import { defaultCertificateInfoData } from "../constants/certitificateInfo";
import CertificateTestimonial from "../CertificateTestimonial";
import { defaultTestimonialData } from "../constants/certificateTestimonial";
import CertificateStats from "../CertificateStats";
import { defaultCertificateStatsData } from "../constants/certificateStats";
import CertificateImage from "../CertificateImage";
import WaysToTakeCourseTable from "../CertificateWaysToTakeCourse/WaysToTakeCourseTable";
import getCoursePrice from "@/lib/course/utils/getCoursePrice";
import EnrollLink from "../../CourseEnroll/CourseEnrollButtons/EnrollLink";
import AuditLink from "../../CourseEnroll/CourseEnrollButtons/AuditLink";
import {
  CertificateEmphasisInformation,
  CertificateEmphasisTestimonial,
  CertificateEmphasisStats,
} from "@/monarch/rules/certificateEmphasisRedesign/types";
import { ElementViewed } from "@/hooks/eventing/types/redventures.usertracking.v3.ElementViewed";
import {
  DEFAULT_USE_IN_VIEW_OPTIONS,
  ElementType,
} from "@/constants/tagular/main";
import { useInView } from "react-intersection-observer";
import useElementEvents from "@/hooks/eventing/useElementEvents";

interface CertificateEmphasisProps {
  course: ProcessedCourse;
  courseRun: CourseRun;
  information?: CertificateEmphasisInformation;
  stats?: CertificateEmphasisStats;
  testimonial?: CertificateEmphasisTestimonial;
}
export default function CertificateEmphasis({
  course,
  courseRun,
  information,
  stats,
  testimonial,
}: CertificateEmphasisProps) {
  const coursePrice = getCoursePrice(courseRun);

  const webElement: ElementViewed["webElement"] = {
    elementType: ElementType.Component,
    htmlId: "value-prop",
    location: "cert-module",
    position: "1",
    name: "value-prop-module",
  };

  const { ref, inView } = useInView(DEFAULT_USE_IN_VIEW_OPTIONS);
  const { elementViewed } = useElementEvents({ webElement });

  useEffect(() => {
    if (inView) {
      elementViewed();
    }
  }, [inView, elementViewed]);

  return (
    <div ref={ref}>
      <div className="hidden lg:grid grid-cols-2 gap-8 bg-gradient-to-b from-secondary-500 to-primary-500 p-16 border rounded-xl">
        <div className="flex flex-col gap-8 mr-8">
          <CertificateInfo
            {...(information || defaultCertificateInfoData)}
            title="About the certificate"
          />
          <div className="w-full border-t border-gray-400" />
        </div>
        <div className="flex justify-center">
          <CertificateImage width={518} height={371} />
        </div>
        <div className="flex flex-col gap-8 items-center mr-8">
          <CertificateTestimonial
            {...(testimonial || defaultTestimonialData)}
            title=""
          />
          <div className="w-full border-t border-gray-400" />
        </div>
        <div>
          <WaysToTakeCourseTable coursePrice={coursePrice} rowHeight="45px" />
        </div>
        <div className="-mt-8">
          <CertificateStats {...(stats || defaultCertificateStatsData)} />
        </div>
        <div className="flex flex-col gap-8 justify-center">
          <div className="w-full flex justify-center items-center gap-2">
            <EnrollLink
              buttonClass="flex-1"
              courseRun={courseRun}
              course={course}
              location="cert-module"
            />
            <AuditLink
              courseRun={courseRun}
              course={course}
              className="flex-1"
              location="cert-module"
            />
          </div>
        </div>
      </div>
      <div className="lg:hidden flex flex-col gap-10 bg-gradient-to-b from-secondary-500 to-primary-500 py-10 px-4">
        <CertificateInfo
          {...defaultCertificateInfoData}
          title="About the certificate"
        />
        <CertificateImage width={544} height={371} />
        <div className="w-full border-t border-gray-400" />
        <CertificateTestimonial
          {...(testimonial || defaultTestimonialData)}
          title=""
        />
        <div className="w-full border-t border-gray-400" />
        <CertificateStats {...defaultCertificateStatsData} />
        <WaysToTakeCourseTable coursePrice={coursePrice} rowHeight="45px">
          <div className="flex flex-col md:flex-row gap-2 sm:gap-3 items-center">
            <EnrollLink
              buttonClass="flex-1"
              courseRun={courseRun}
              course={course}
              location="cert-module"
            />
            <AuditLink
              courseRun={courseRun}
              course={course}
              className="flex-1"
              location="cert-module"
            />
          </div>
        </WaysToTakeCourseTable>
      </div>
    </div>
  );
}
