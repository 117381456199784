import * as React from "react";
import { CertificateWaysToTakeCourseProps } from "./types";
import getCoursePrice from "@/lib/course/utils/getCoursePrice";
import WaysToTakeCourseTable from "./WaysToTakeCourseTable";
import { defaultTableDescriptionData } from "@/components/CourseContent/ContentRefresh/Certificate/constants/certificateTableDescription";

export default function CertificateWaysToTakeCourse({
  activeCourseRun,
  children,
  tableDescription,
}: CertificateWaysToTakeCourseProps) {
  const coursePrice = getCoursePrice(activeCourseRun); //TODO: add user currency to this logic
  const title = tableDescription?.title || defaultTableDescriptionData.title;
  const subtitle =
    tableDescription?.subtitle || defaultTableDescriptionData.subtitle;
  const highlightedText =
    tableDescription?.highlightedText ||
    defaultTableDescriptionData.highlightedText;

  return (
    <div className="mx-auto py-8 md:py-16 px-5 md:px-16 max-w-3xl lg:max-w-screen-xl bg-gradient-to-b from-secondary-500 to-primary-500 md:rounded-xl">
      <div className="flex flex-col lg:flex-row md:gap-20">
        <div className={`flex-1 lg:w-1/4`}>
          <h2 className="font-inter leading-[3rem] text-4xl md:text-6xl mt-0 mb-4 text-white font-bold italic tracking-[-0.06rem]">
            {title}
          </h2>
          <div className="mb-10">
            <p className="text-base mb-10" style={{ color: "white" }}>
              {subtitle}
            </p>
          </div>
          <div className="font-inter leading-[3rem] text-6xl mt-0 mb-6 text-white font-bold italic tracking-[-0.06rem]">
            ${coursePrice}
          </div>
          <div className="text-lg font-bold" style={{ color: "white" }}>
            {highlightedText}
          </div>
        </div>

        <div className="flex-1 pt-2 md:pt-0">
          <div className="flex flex-col">
            <WaysToTakeCourseTable coursePrice={coursePrice}>
              {children}
            </WaysToTakeCourseTable>
          </div>
        </div>
      </div>
    </div>
  );
}
