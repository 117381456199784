import * as React from "react";
import Icon from "@/components/icons";
import { WaysToTakeCourseTableProps } from "./types";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import useCertificateEmphasisRedesign from "@/monarch/rules/certificateEmphasisRedesign/useCertificateEmphasisRedesign";

let COMPARISON_SECTIONS = [
  { element: "Course materials", col1: "Unlimited", col2: "Limited" },
  { element: "edX support", col1: "x", col2: "x" },
  { element: "Career network", col1: "x", col2: "x" },
  { element: "Shareable certificate", col1: "x", col2: "" },
  { element: "Graded assignments", col1: "x", col2: "" },
  { element: "Price", col1: "", col2: "Free" },
];

const toolTipMessages = [
  `Unlimited access and share your success on Linkedin.`,
  `Limited access without a certificate.`,
];

function getInfoToolTip(text: string) {
  return (
    <Popover>
      <PopoverTrigger className="ml-2 relative">
        <Icon type="info" svgProps={{ width: "18", height: "18" }} />
      </PopoverTrigger>
      <PopoverContent className="bg-primary p-4 rounded-xl max-w-56 mx-auto text-white text-sm">
        {text}
      </PopoverContent>
    </Popover>
  );
}
export default function WaysToTakeCourseTable({
  coursePrice,
  children,
  rowHeight = "60px",
}: WaysToTakeCourseTableProps) {
  const { hidePrice } = useCertificateEmphasisRedesign();

  if (!hidePrice) {
    COMPARISON_SECTIONS[5].col1 = `$${coursePrice}`;
  }

  const lastIndex = hidePrice
    ? COMPARISON_SECTIONS.length - 2
    : COMPARISON_SECTIONS.length - 1;

  return (
    // TABLE
    <div className="flex-1 pt-2 md:pt-0">
      <div className="grid grid-cols-[2fr_1fr_1fr] divide-y divide-primary-300">
        {/* HEADER ROW */}
        <div
          className="col-start-2 justify-items-center md:px-1 bg-brand-500 border border-brand-500"
          style={{ borderTopLeftRadius: "10px" }}
        >
          <div
            className="w-full max-w-[220px] text-center content-center"
            style={{ height: rowHeight }}
          >
            <div className="px-2 text-white text-xs md:text-base font-bold flex flex-row justify-center">
              Certificate
              {getInfoToolTip(toolTipMessages[0])}
            </div>
          </div>
        </div>
        <div
          className="bg-primary-700 justify-items-center md:px-8 border border-primary-700"
          style={{ borderTopRightRadius: "10px" }}
        >
          <div
            className="w-full max-w-[220px] text-center content-center"
            style={{ height: rowHeight }}
          >
            <div className="px-2 text-white text-xs md:text-base font-bold flex flex-row justify-center">
              Audit
              {getInfoToolTip(toolTipMessages[1])}
            </div>
          </div>
        </div>

        {/* DATA COMPARISON ROWS */}
        {COMPARISON_SECTIONS.map((section, index) => {
          if (section.element === "Price" && hidePrice) {
            return null;
          }
          let showCol2 = true;
          let showCol1 = true;
          return (
            <React.Fragment key={index}>
              {/* ELEMENT */}
              <div
                className={`content-center bg-putty-200`}
                style={{
                  borderTopLeftRadius: index === 0 ? "10px" : "",
                  borderBottomLeftRadius: index === lastIndex ? "10px" : "",
                  height: rowHeight,
                }}
              >
                <div className="text-xs md:text-sm font-bold ml-3 md:ml-6 text-primary-500">
                  {section.element}
                </div>
              </div>

              {/* COL1 */}
              <div
                className={`content-center inline-grid text-center text-white justify-items-center ${index === lastIndex && !hidePrice ? "bg-brand-500 text-base font-bold" : "bg-brand-700 text-sm"}`}
              >
                {section.col1 === "x" &&
                  ((showCol1 = false),
                  (
                    <div className="rounded-full">
                      <Icon
                        type="circle-check-fill"
                        svgProps={{ width: "22", height: "22" }}
                        pathProps={{ fill: "white" }}
                      />
                    </div>
                  ))}
                {section.col1 === "Unlimited" &&
                  ((showCol1 = false),
                  (
                    <div className="content-center inline-grid text-center text-sm bg-brand-700">
                      {section.col1}
                    </div>
                  ))}
                {showCol1 && (
                  <div className="content-center inline-grid text-center text-base font-bold bg-brand-500">
                    {section.col1}
                  </div>
                )}
              </div>

              {/* COL2 */}
              <div
                className={`content-center inline-grid text-center text-sm text-white justify-items-center ${index === lastIndex && !hidePrice ? "bg-primary-500" : "bg-primary-700"} `}
                style={{
                  borderBottomRightRadius: index === lastIndex ? "10px" : "",
                }}
              >
                {section.col2 === "x" &&
                  ((showCol2 = false),
                  (
                    <div className="rounded-full">
                      <Icon
                        type="circle-check-fill"
                        svgProps={{ width: "22", height: "22" }}
                        pathProps={{ fill: "white" }}
                      />
                    </div>
                  ))}
                {section.col2 === "" ||
                  (section.col2 === "Limited" &&
                    ((showCol2 = false),
                    (
                      <div className="content-center inline-grid text-center text-lsm bg-primary-700">
                        {section.col2}
                      </div>
                    )))}
                {showCol2 && (
                  <div className="content-center inline-grid text-center text-base font-bold bg-primary-500">
                    {section.col2}
                  </div>
                )}
              </div>
            </React.Fragment>
          );
        })}
      </div>

      {/* BUTTONS */}
      {children && (
        <div className="pt-8 md:justify-between gap-4">{children}</div>
      )}
    </div>
  );
}
